


























































































import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import { apiBannerList, apiBannerDel, apiBannerStatus } from '@/api/imagetru3d'
@Component({
  components: {
    LsDialog,
  },
})
export default class Banner extends Vue {
  $refs!: { plTreeTable: any }
  pager = new RequestPaging()

  // 获取轮播图列表
  getBannerList(): void {
    this.pager
      .request({
        callback: apiBannerList,
        params: { pager_type: 1 },
      })
      .then((res) => {})
  }

  // 轮播图状态调整
  handleStatus(value: number, id: number) {
    apiBannerStatus({
      id,
      show: value,
    }).then(() => {
      this.getBannerList()
    })
  }

  // 轮播图删除
  handleDelete(id: number) {
    apiBannerDel(id).then(() => {
      this.getBannerList()
      this.$message.success('轮播图删除成功')
    })
  }

  // 创建当前组件的时候，获取轮播图
  created() {
    this.getBannerList()
  }
}
